import { graphql } from 'gatsby'
import React from 'react'
import * as PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/Layout'
import { LocationContext } from '../utils/Contexts'

import components from '../components/mdx-components'
import GoogleReview from '../components/GoogleReview'

const PageTemplate = ({ data, location }) => {
  const {
    title,
    description,
    contentful_id: contentfulId,
    node_locale: locale,
    content: {
      childMdx: { body }
    }
  } = data.contentfulPage

  const { rating } = data.googlePlacesPlace

  return (
    <LocationContext.Provider
      value={{
        activeContentfulId: contentfulId,
        activeLocale: locale,
        location
      }}
    >
      <Layout>
        <Helmet
          /**
           * Meta information based on:
           * https://moz.com/blog/meta-data-templates-123
           * https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/ConfiguringWebApplications/ConfiguringWebApplications.html
           */
          title={title}
          meta={[
            {
              property: 'og:title',
              content: title
            },
            {
              name: 'description',
              content: description
            },
            {
              property: 'og:description',
              content: description
            }
            // heroImage && {
            //   property: 'twitter:image:src',
            //   content: `${seoImage.file.url}?w=1200&h=628&fit=fill`
            // },
            // heroImage && {
            //   property: 'og:image',
            //   content: `${seoImage.file.url}?w=1200&h=630&fit=fill`
            // }
          ].filter(Boolean)}
        />
        <MDXProvider components={components}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
        {rating && <GoogleReview rating={rating} />}
      </Layout>
    </LocationContext.Provider>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      contentful_id
      title
      slug
      description
      content {
        childMdx {
          body
        }
      }
    }
    googlePlacesPlace(
      id: { eq: "GooglePlaces__Place__ChIJD9rOy9RRqEcRQ-_-wHUJ9kg" }
    ) {
      rating
    }
  }
`
